<template>
  <div>
    <h3 class="text-gray-700 text-3xl font-medium">Einstellungen</h3>
  </div>
</template>

<script>


export default {
  name: "Settings",
  data(){
    return {

    }
  }
}
</script>

<style scoped>

</style>
